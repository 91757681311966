import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Breadcrumbs from "src/utils/Breadcrumbs"
import Pricing from "src/components/Product/Pricing"
import Fade from "react-reveal/Fade"
import ProductAccordion from "src/components/Product/ProductAccordion"

const ArchiveGarageDoorPricing = ({ data: { allPrismicGarageDoor } }) => {
  const data = allPrismicGarageDoor

  const crumbs = [
    { label: "Garage Doors", href: "/garage-doors" },
    {
      label: "Garage Door Pricing",
      href: "/garage-doors/prices",
      active: true,
    },
  ]

  return (
    <main>
      <Seo title={"Garage Door Pricing"} 
        description={
          "See here for approximate guide prices for our bespoke, contemporary Garage doors. Call us today or request an online quotation."
        }
      />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <Pricing {...data} type={"garage door"} />
      <ProductAccordion type="garage-doors" />
      <section className="bg-offwhite">
        <div className="container text-center">
          <Fade distance="20px" bottom>
            <h2 className="font-display text-gold max-w-lg m-auto rfs:text-6xl">
              Internal and Front Door Pricing
            </h2>
            <div className="w-10 separator mt-4"></div>
            <p className="max-w-xl m-auto mt-6">
              See our pricing for internal and front doors, where you can
              seamlessly match the door style or woods and finishes across
              different door styles.
            </p>
          </Fade>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-12 text-left">
            <Fade distance="20px" bottom>
              <Link
                to={`/front-doors/prices/`}
                className="group"
                title={`Front Door Pricing`}
              >
                <img
                  src="https://images.prismic.io/deuren/9e4a7dfd-641d-402e-91bc-dc239f3fb1ec_Deuren-Front-Door-Pricing-Discounts-available.jpg?auto=compress,format"
                  alt="Front Door Pricing"
                />
                <p
                  className={`font-bold text-xs tracking-widest uppercase mt-4 group-hover:text-gold duration-500`}
                >
                  FRONT DOOR PRICING
                </p>
              </Link>
              <Link
                to={`/internal-doors/prices/`}
                className="group"
                title={`Internal Door Pricing`}
              >
                <img
                  src="https://images.prismic.io/deuren/f12a0682-ddb3-44b2-9eb8-d868806286e5_Deuren-Internal-Door-Pricing-Discounts-available.jpg?auto=compress,format"
                  alt="Deuren Internal Door Pricing Discounts available"
                />
                <p
                  className={`font-bold text-xs tracking-widest uppercase mt-4 group-hover:text-gold duration-500`}
                >
                  Internal DOOR PRICING
                </p>
              </Link>
            </Fade>
          </div>
        </div>
      </section>
      <section className="bg-verylightgrey">
        <div className="container text-center">
          <Fade distance="20px" bottom>
            <h2 className="font-display text-gold rfs:text-6xl">
              Download the brochure
            </h2>
            <div className="w-12 mt-5 separator"></div>
            <p className="max-w-2xl my-6 m-auto">
              Find out more about our front, internal and garage door ranges.
              Featuring our most popular designs along with technical
              specifications, configuration information, our range of woods and
              finishes, and available accessories, you can pick your perfect new
              door from the comfort of your own home.
            </p>
            <Link className="btn" to={`/brochure-downloads/`} role="button">
              Download the brochure
            </Link>
          </Fade>
        </div>
      </section>
    </main>
  )
}

export default ArchiveGarageDoorPricing

export const garageDoorPricingQuery = graphql`
  query GarageDoorPricing {
    allPrismicGarageDoor {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            pricing {
              price
              title {
                text
              }
            }
            pricing_image {
              url
              alt
            }
          }
        }
      }
    }
  }
`
